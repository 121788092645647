exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-chat-app-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/chat-app/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-chat-app-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-data-visualisation-choropleth-map-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/data-visualisation/choropleth-map.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-data-visualisation-choropleth-map-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-data-visualisation-treemap-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/data-visualisation/treemap.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-data-visualisation-treemap-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-js-calculator-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/js_calculator/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-js-calculator-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-ai-vs-ml-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/ai-vs-ml.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-ai-vs-ml-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-bash-commands-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/bash-commands.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-bash-commands-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-bash-scripting-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/bash-scripting.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-bash-scripting-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-express-server-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/express-server.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-express-server-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-mongodb-and-mongoose-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/mongodb-and_mongoose.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-mongodb-and-mongoose-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-postgresql-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/postgresql.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-postgresql-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-remove-git-submodule-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/notes/remove-git-submodule.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-notes-remove-git-submodule-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-redux-middleware-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/redux-middleware/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-redux-middleware-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-regex-lookarounds-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/regex/lookarounds.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-regex-lookarounds-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-sudoku-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/sudoku/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-sudoku-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-test-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/test.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-test-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-hold-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/tetris/hold.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-hold-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-index-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/tetris/index1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-index-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-sound-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/tetris/sound.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-sound-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-swipe-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/wenyuzheng/React/gatsby-personal-site/blog/tetris/swipe.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-tetris-swipe-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

