import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Code from "./Code";

const components = {
  pre: (props) => <Code {...props} />,
  p: (props) => <p style={{ fontSize: 20, lineHeight: 1.6 }} {...props} />,
  h2: (props) => (
    <h2 style={{ marginTop: 50, borderBottom: "1px solid grey" }} {...props} />
  ),
  h3: (props) => <h3 style={{ marginTop: 40 }} {...props} />,
  wrapper: ({ children }) => <>{children}</>,
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
